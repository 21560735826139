@media only screen and (max-width: 600px) {
    .responsive-modal {
      max-width: 98vw;
    }
  }

@media only screen and (min-width: 601px) {
    .responsive-modal {
      max-width: 85vw;
    }
  }

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
}

button.close {display: none;} */