//
// _horizontal.scss
//
@import "~react-pro-sidebar/dist/scss/styles.scss";

.topnav {
    background: $topnav-bg;
    padding: 0 calc(#{$grid-gutter-width} / 2);
    box-shadow: $box-shadow;
    margin-top: $header-height;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;

    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            font-size: $font-size-base;
            position: relative;
            padding: 0.5rem 1.5rem;
            color: $menu-item-color;
            i {
                font-size: 15px;
            }
            &:focus,
            &:hover {
                color: $menu-item-active-color;
                //Main nav header
                background-color: rgba(168, 245, 168, 0.363);
            }
        }

        .dropdown-item {
            color: $menu-item-color;
            color: rgba(#34c38f, 1) !important;

            &.active,
            &:hover {
                color: $menu-item-active-color;
            }
        }

        .nav-item {
            .nav-link.active {
                color: $menu-item-active-color;
            }
        }

        .dropdown {
            &.active {
                > a {
                    color: $menu-item-active-color;
                }
            }
        }
    }
}

// .lds-spinner {
//     color: official;
//     display: inline-block;
//     position: relative;
//     width: 20px;
//     height: 20px;
// }
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: rgb(113, 231, 35);
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

//Full width of the port
@include media-breakpoint-up(xl) {
    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown-item {
            padding: 0.5rem 1rem;
            //WIDTH OF THE ITEM
            min-width: "fit-content";
        }

        .dropdown {
            &.mega-dropdown {
                // position: static;
                .mega-dropdown-menu {
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none;
                    }
                }
            }

            &:hover {
                > .dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
            display: block;
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: 0.4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all 0.3s ease-out;
        width: 0.4em;
    }
}

@include media-breakpoint-down(xl) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .navbar-brand-box {
        .logo-dark {
            display: $display-block;
            span.logo-sm {
                display: $display-block;
            }
        }

        .logo-light {
            display: $display-none;
        }
    }

    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown:hover {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
                display: block;
                z-index: 20000;
                &.dropdown-mega-menu-xl {
                    width: auto;

                    .row {
                        margin: 0px;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}

input:out-of-range {
    border-color: red;
}

input[type="number"]::-webkit-inner-spin-button {
    cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
    border-color: red;
    color: "green";
}
.check {
    display: none;
}

.scrollBehaviour {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
}

@media screen and (max-width: 1200px) {
    .scrollBehaviour {
        height: 100% !important;
        overflow: scroll !important;
    }
}
@media screen and (max-width: 992px) {
    .scrollBehaviour {
        height: 100% !important;
        overflow: scroll !important;
    }
}
@media screen and (max-width: 992px) {
    .scrollBehaviour {
        height: 100% !important;
        overflow: scroll !important;
    }
    .check {
        display: block;
        position: fixed;
        background-color: rgba(#34c38f, 1);
        bottom: 4%;
        right: 5%;
        padding: 1.2rem 0.9rem;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0px 0px 10px rgb(156, 154, 154);
        transition: all 0.5s;
        cursor: pointer;
    }

    .check:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        z-index: -5500;

        border-radius: 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(#34c38f, 1);
        //background-color: rgba(#b734c3, 1);
        // transition: all 0.5s;
    }
    .check:active {
        transform: scale(0.95);
        box-shadow: 0px 0px 10px rgb(177, 175, 175);

        opacity: 0.3;
    }
    // #navigation, #language-dropdown{
    //     display: none;
    // }
}

.test {
    cursor: pointer;
}
.tested {
    display: none;
}

.test:hover ~ .tested {
    display: block;
}
.tested:hover {
    display: block;
}

.burgerButtonCover {
    margin-top: 5rem;
    z-index: 0;
    position: relative;
    padding: 2rem;
    background-color: rgba(223, 223, 236, 0.726);
}
.burgerButton {
    position: relative;
    display: block;
    transition: all 0.3s;
    // padding: 2rem;
    background-color: rgba(223, 223, 236, 0.726);

    // background-color: rgba(240, 240, 241, 0.726);
}

.burgerButton,
.burgerButton::after,
.burgerButton::before {
    width: 25px;
    height: 2px;
    background-color: rgb(2, 2, 2);
}

.burgerButton::after,
.burgerButton::before {
    content: "";
    position: absolute;
    left: 0;
}

.burgerButton::after {
    bottom: 6px;
}

.burgerButton::before {
    top: 6px;
}

@include media-breakpoint-up(lg) {
    body[data-layout="horizontal"][data-topbar="light"] {
        .navbar-brand-box {
            .logo-dark {
                display: $display-block;
            }

            .logo-light {
                display: $display-none;
            }
        }

        .topnav {
            //ENTIRE NAVBAR COLOR
            background-color: white;
            .navbar-nav {
                .nav-link {
                    color: rgba(#34c38f, 1) !important;

                    &:focus,
                    &:hover {
                        color: rgba(#34c38f, 0.9);
                    }
                }

                > .dropdown {
                    &.active {
                        > a {
                            color: rgba(#34c38f, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}

// Colored Topbar

body[data-layout="horizontal"][data-topbar="colored"] {
    #page-topbar {
        background-color: $primary;
        box-shadow: none;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
        .form-control {
            background-color: rgba($topbar-search-bg, 0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white, 0.5);
        }
    }
    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }

    .navbar-header {
        .dropdown .show {
            &.header-item {
                background-color: rgba($white, 0.1);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    @include media-breakpoint-up(lg) {
        .topnav {
            background-color: $primary;
            .navbar-nav {
                .nav-link {
                    color: rgba($white, 0.6);

                    &:focus,
                    &:hover {
                        color: rgba($white, 0.9);
                    }
                }

                > .dropdown {
                    &.active {
                        > a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}
